import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useInputVerify, { STATUS } from '../../../hooks/useInputVerify';
import { Prompt } from 'react-router-dom';
import _ from 'lodash';

import { hideBackIcon, showBackIcon } from 'reducers/headerReducer';
import { fetchHeroBanner } from 'reducers/heroBannerReducer';

import { RangeDatePicker, DatePicker } from '@y0c/react-datepicker';
import { addHours, addMinutes } from 'date-fns';
import dayjs from 'dayjs';

import DropdownButton from 'components/common/DropdownButton';
import Input from 'components/common/BorderInput';
import UploadImgButton from 'components/common/UploadButton';
import DropdownList from 'components/common/DropdownList';
import Button from 'components/common/Button';
import ErrorText from 'components/common/ErrorText';
import DeleteModal from 'components/common/DeleteModal';
import Spinner from 'components/common/Spinner';
import TextArea from 'components/common/TextArea';

import HeroBannerApi from 'utils/apicallers/herobannerapi';
import { checkFileSize, fetchMatchPattern } from 'utils/common';

import { wordLimitation, fileExtensions } from 'consts/appConsts';
import {
  ACTION,
  CTA_TYPE,
  DEDAULT_DATA
} from '../const';
import {
  SHOW_HERO_BANNER_DETAIL,
  SHOW_ERROR
} from 'components/universalheader/consts';
import { DEL_LIST } from 'components/common/DropdownButton/const';
import { API_TYPE } from 'components/common/DeleteModal/const';

import btseNFT from '../../../assets/imgs/btse-banner.png';

import styles from './styles.module.scss';

const options = [
  {
    key: CTA_TYPE.EVENT,
    text: 'Event'
  },
  {
    key: CTA_TYPE.ARTIST,
    text: 'Artist'
  },
  {
    key: CTA_TYPE.URL,
    text: 'Web URL'
  },
];

const uploadId = 'add-banner-img';
const previewId = `${uploadId}_output`;

const patternMatch = fetchMatchPattern(/(?:\.([^.]+))?$/);

export default function Banner({ action = ACTION.CREATE }) {
  const herobannerId = useSelector(state => {
    return state.HeroBannerReducer.herobannerId;
  });
  const detailData = useSelector(state => {
    return state.HeroBannerReducer.detailData;
  });
  const [delVisible, setDelVisible] = useState(false);
  const initStartDayObj = action === ACTION.CREATE ? dayjs().startOf('day') : dayjs(detailData.startTime);
  const initEndDayObj = action === ACTION.CREATE ? dayjs().endOf('day') : dayjs(detailData.endTime);
  const tempTimeRef = useRef({
    tempStarthour: initStartDayObj.$H,
    tempStartmin: initStartDayObj.$m,
    tempEndhour: initEndDayObj.$H,
    tempEndmin: initEndDayObj.$m,
  });
  const [banner, setBanner] = useState(
    action === ACTION.CREATE
    ? {
        ...DEDAULT_DATA,
        startTime: dayjs().startOf('day').valueOf(),
        endTime: dayjs().endOf('day').valueOf()
      }
    : {...detailData}
  );
  const [apiLoading, setApiLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadErrMsg, setUploadErrMsg] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (herobannerId && action === ACTION.UPDATE) {
      setApiLoading(true);
      setErrMsg('');
      HeroBannerApi
        .getHeroBannerById(herobannerId)
        .then(res => {
          const { subTitle, ...rest } = res.data.data;
          dispatch(fetchHeroBanner({
            ...rest,
            subTitle: subTitle || []
          }));
          setBanner(res.data.data);
          const initStartDayObj = dayjs(rest.startTime);
          const initEndDayObj = dayjs(rest.endTime);
          tempTimeRef.current = {
            tempStarthour: initStartDayObj.$H,
            tempStartmin: initStartDayObj.$m,
            tempEndhour: initEndDayObj.$H,
            tempEndmin: initEndDayObj.$m,
          };
          setApiLoading(false);
        })
        .catch(err => {
          console.error(err);
          dispatch(showBackIcon(SHOW_ERROR));
          setApiLoading(false);
        });
    }
  }, [herobannerId]);

  // TODO: remove useInputVerify to simplify validate flow
  const [
    titleStatus,
    titleErrMsg,
    titleRef,
    setTitleStatus,
    resetTitleStatus
  ] = useInputVerify();
   const [
    orderStatus,
    orderErrMsg,
    orderRef,
    setOrderStatus,
    resetOrderStatus
   ] = useInputVerify();
  const [
    mainTitleStatus,
    mainTitleErrMsg,
    mainTitleRef,
    setMainTitleStatus,
    resetMainTitleStatus
  ] = useInputVerify();
  const [
    subTitleStatus,
    subTitleErrMsg,
    subTitleRef,
    setsubTitleStatus,
    resetSubTitleStatus
  ] = useInputVerify();
  const [
    subTitleStatus2,
    subTitleErrMsg2,
    subTitleRef2,
    setsubTitleStatus2,
    resetSubTitleStatus2
  ] = useInputVerify();

  const [
    ctaStatus,
    ctaErrMsg,
    ctaRef,
    setCtaStatus,
    resetCtaStatus
  ] = useInputVerify();

  const [
    ctaValueStatus,
    ctaValueErrMsg,
    ctaValueRef,
    setCtaValueStatus,
    resetCtaValueStatus
  ] = useInputVerify();

  const [timeErrMsg, setTimeErrMsg] = useState('');

  const gotoSummary = () => {
    dispatch(hideBackIcon());
  };

  const handleUpload = file => {
    setUploadErrMsg('');
    if (!checkFileSize(file.size, 5)) {
      setUploadErrMsg('File size is larger than 5 MB.');
      return;
    }

    const fileExtension = patternMatch(file.name);
    if(!fileExtensions.img.includes(fileExtension)){
      setUploadErrMsg('file type not supported');
      return;
    }

    const heightSize = 960;
    const widthSize = 1600;
    const imgObj = new Image();
    const imgSrc = URL.createObjectURL(file);
    imgObj.src = imgSrc;
    imgObj.onload = () => {
      const { height, width } = imgObj;
      URL.revokeObjectURL(imgSrc);
      if ((height < heightSize || width < widthSize)) {
        setUploadErrMsg('smaller than 1600 * 900');
        return;
      }
      const params = new FormData();
      params.append('image', file);
      setIsUploading(true);
      HeroBannerApi.uploadBackground(params)
        .then(res => {
          const { background } = res.data.data;
          setBanner(prev => ({
            ...prev,
            background
          }));
        })
        .catch(err => {
          console.error('err', err);
          setUploadErrMsg(`upload fail: ${err?.response?.data?.msg || ''}`);
        })
        .finally(() => {
          setIsUploading(false);
        });
    };
  };

  const handleInput = (key, value) => {
    setBanner(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const handleRangeDate = (start, end) => {
    if (start && end) {

      const startOnlyDateTimeStamp = 
        dayjs(start.valueOf()).startOf('day').valueOf();
      const endOnlyDateTimeStamp =
        dayjs(end.valueOf()).startOf('day').valueOf();

      let startResult = addHours(startOnlyDateTimeStamp, tempTimeRef.current.tempStarthour);
      startResult = addMinutes(startResult, tempTimeRef.current.tempStartmin);
      let endResult = addHours(endOnlyDateTimeStamp, tempTimeRef.current.tempEndhour);
      endResult = addMinutes(endResult, tempTimeRef.current.tempEndmin);

      setBanner(prev => ({
        ...prev,
        startTime: dayjs(startResult).valueOf(),
        endTime: dayjs(endResult).valueOf()
      }));
    }
  };

  const calTime = (key, date) => {
    const hour = date.$H;
    const min = date.$m;

    if(key === 'start'){
      tempTimeRef.current.tempStarthour = hour;
      tempTimeRef.current.tempStartmin = min;
    }
    if(key === 'end'){
      tempTimeRef.current.tempEndhour = hour;
      tempTimeRef.current.tempEndmin = min;
    }

    const currentTime = key === 'start'
      ? banner.startTime
      : banner.endTime;
    
    const zeroScheduleTime = dayjs(currentTime).startOf('day').valueOf();
    let result = addHours(zeroScheduleTime, hour);
    result = addMinutes(result, min);

    return result.valueOf();
  };

  const handleTime = (key, date) => {
    const result = calTime(key, date);
    if(key === 'start'){
      setBanner(prev => ({
        ...prev,
        startTime: result,
      }));
    }else{
      setBanner(prev => ({
        ...prev,
        endTime: result,
      }));
    }
  };

  const handleSubTitleChange = (e, index) => {
    const length = e.length;
    if(index == 1){
      if(length > wordLimitation && subTitleStatus2 !== STATUS.INVALID){
        setsubTitleStatus2(STATUS.INVALID, 'Not over 500 words');
      }
      if(subTitleStatus2 == STATUS.INVALID && length <= wordLimitation ){
        setsubTitleStatus2(STATUS.VALID, '');
      }
    }
    let newSubTitle = [...banner.subTitle];
    newSubTitle[index] = e;
    setBanner(prev => ({
      ...prev,
      subTitle: newSubTitle
    }));
  };

  const handleCtaTypeChange = e => {
    setBanner(prev => ({
      ...prev,
      ctaType: e.key
    }));
  };

  const showCtaValuePlaceHolder = () => {
    switch (banner.ctaType) {
      case CTA_TYPE.EVENT:
        return 'Enter Event ID';
      case CTA_TYPE.URL:
        return 'Enter Web URL';
      case CTA_TYPE.ARTIST:
        return 'Enter Artist';
      default:
        return '';
    }
  };

  const showCtaValue = () => {
    switch(banner.ctaType) {
      case CTA_TYPE.EVENT:
        return banner.eventId;
      case CTA_TYPE.URL:
        return banner.url;
      case CTA_TYPE.ARTIST:
        return banner.artistId;
      default:
        return '';
    }
  };

  const handleCtaValueChange = e => {
    setBanner(prev => ({
      ...prev,
      ...(banner.ctaType === CTA_TYPE.EVENT && { eventId: e }),
      ...(banner.ctaType === CTA_TYPE.ARTIST && { artistId: e }),
      ...(banner.ctaType === CTA_TYPE.URL && { url: e })
    }));
  };

  const handleCancel = () => {
    if (action === ACTION.UPDATE) {
      dispatch(showBackIcon(SHOW_HERO_BANNER_DETAIL));
      // dispatch({
      //   type: SHOW_BACK_ICON,
      //   payload: SHOW_HERO_BANNER_DETAIL
      // });
    } else {
      dispatch(hideBackIcon());
      // dispatch({
      //   type: HIDE_BACK_ICON
      // });
    }
  };

  const verifyBannerName = () => {
    if (!banner.bannerName) {
      setTitleStatus(STATUS.INVALID, 'This field is required.');
      return false;
    } else {
      setTitleStatus(STATUS.VALID, '');
      return true;
    }
  };
  const verifyMainTitle = () => {
    if (!banner.mainTitle) {
      setMainTitleStatus(STATUS.INVALID, 'This field is required.');
      return false;
    } else {
      setMainTitleStatus(STATUS.VALID, '');
      return true;
    }
  };
  const verifyCta = () => {
    if (!banner.cta) {
      setCtaStatus(STATUS.INVALID, 'This field is required.');
      return false;
    } else {
      setCtaStatus(STATUS.VALID, '');
      return true;
    }
  };
  const verifySubTitle = () => {
    if (!banner.subTitle[0] || !banner.subTitle[1]) {
      setsubTitleStatus(STATUS.INVALID, 'This field is required.');
      return false;
    } else {
      setsubTitleStatus(STATUS.VALID, '');
      return true;
    }
  };
  const verifyCtaValue = () => {
    if (!banner.eventId && !banner.artistId && !banner.url) {
      setCtaValueStatus(STATUS.INVALID, 'This field is required.');
      return false;
    } else {
      setCtaValueStatus(STATUS.VALID, '');
      return true;
    }
  };
  const verifyTime = () => {
    if (banner.startTime > banner.endTime) {
      setTimeErrMsg('Invalid time');
      return false;
    }
    setTimeErrMsg('');
    return true;
  };

  const handleAction = () => {
    let pass = true;
    const verifyFns = [
      verifyBannerName(),
      verifyMainTitle(),
      verifySubTitle(),
      verifyCta(),
      verifyCtaValue(),
      verifyTime()
    ];
    pass = verifyFns.every(fn => fn === true);
    if (!pass) {
      return;
    }
    const {
      bannerId,
      bannerName, 
      order, 
      startTime, 
      endTime, 
      background, 
      mainTitle, 
      subTitle, 
      cta, 
      ctaType, 
      eventId, 
      artistId, 
      url
    } = banner;
    const params = {
      bannerName,
      order,
      startTime,
      endTime,
      background,
      mainTitle,
      subTitle,
      cta,
      ctaType,
      ...(ctaType === CTA_TYPE.EVENT && { eventId }),
      ...(ctaType === CTA_TYPE.ARTIST && { artistId }),
      ...(ctaType === CTA_TYPE.URL && { url })
    };
    setIsSubmitting(true);
    if (action === ACTION.UPDATE) {
      HeroBannerApi.update(bannerId, params)
        .then(res => {
          setIsSubmitting(false);
          gotoSummary();
        })
        .catch(err => {
          setIsSubmitting(false);
        });
    } else { // ACTION.CREATE
      HeroBannerApi.create(params)
        .then(res => {
          setIsSubmitting(false);
          gotoSummary();
        })
        .catch(err => {
          console.error(err);
          setIsSubmitting(false);
        });
    }
  };

  const handleDeleteComplete = isSuccess => {
    setDelVisible(false);
    isSuccess && gotoSummary();
  };

  const renderWrapper = content => {
    return (
      <div className={styles.pickerWrapper}>
        {content}
      </div>
    );
  };

  const isSubmittable = (
    banner.bannerName &&
    banner.order &&
    banner.startTime &&
    banner.endTime &&
    banner.background &&
    banner.mainTitle &&
    banner.subTitle[0] &&
    banner.subTitle[1] &&
    banner.cta &&
    banner.ctaType &&
    !isUploading &&
    !isSubmitting
  );

  if (apiLoading) {
    return (
      <Spinner size="medium" />
    );
  }

  if (errMsg) {
    return (
      <ErrorText text={errMsg} align="center" />
    );
  }

  return (
    <div className={styles.container}>
      <Prompt message="" when={true} />
      <DeleteModal
        id={herobannerId}
        visible={delVisible}
        apiType={API_TYPE.HEROBANNER}
        title="Delete Banner"
        errTitle="Not Able to Delete Main Attribute"
        content="Are you sure to delete this banner?"
        errContent="This banner can not be deleted."
        onComplete={handleDeleteComplete}
      />
      <div className={styles['title-container']}>
        <div className={styles.title}>
          {banner.bannerName}
        </div>

        <div className={styles['action-container']}>
          <Button
            text="Cancel"
            type="simple"
            onClick={handleCancel}
          />
          <Button
            text={action === ACTION.UPDATE ? 'Update' : 'Create Banner'}
            onClick={handleAction}
            disabled={!isSubmittable}
          />
          {
            action === ACTION.UPDATE && (
              <DropdownButton 
                list={DEL_LIST}
                onSelect={() => setDelVisible(true)}
              />
            )
          }
        </div>
      </div>

      <div className={styles.wrapper}>
        <div className={styles['image-container']}>
          {
            isUploading
              ? <Spinner size="medium" />
              : (
                <>
                  <div className={styles['img-wrapper']}>
                    <img
                      id={previewId}
                      src={banner.background || btseNFT}
                      width="100%"
                    />
                  </div>
                  
                  <div className={styles.upload}>
                    <UploadImgButton
                      text={action === ACTION.UPDATE ? 'Replace' : 'Upload'}
                      id={uploadId}
                      accept="image/png, image/jpeg, image/jpg"
                      handleUpload={handleUpload}
                    />
                    <div className={styles.hint}>
                      Recommended image size is no smaller than 1600*960 JPG, JPEG, PNG accepted, 5MB limit
                    </div>
                  </div>
                  {
                    uploadErrMsg && <ErrorText text={uploadErrMsg} />
                  }
                </>      
              )
          }
        </div>

        <div className={styles['info-container']}>
          {
            action === ACTION.UPDATE &&
            <div className={styles.section}>
              <div className={styles.title}>Banner ID</div>
              <div className={styles.content}>#{banner.bannerId}</div>
            </div>
          }

          <div className={styles.section}>
            <div className={styles.title}>Banner Title</div>
            <Input
              placeholder="Enter Banner Title"
              enableClearBtn={false}
              value={banner.bannerName}
              onInputChange={e => handleInput('bannerName', e)}
              isError={titleStatus === STATUS.INVALID}
              errMsg={titleErrMsg}
            />
          </div>

          <div className={styles.section}>
            <div className={styles.title}>Order</div>
            <div className={styles.content}>
              <Input
                value={banner.order}
                enableClearBtn={false}
                onInputChange={e => handleInput('order', e)}
                isError={orderStatus === STATUS.INVALID}
                errMsg={orderErrMsg}
              />
            </div>
          </div>

          <div className={styles.section}>
            <div className={styles.title}>Start Date ~ End Date</div>
            <div className={styles.content}>
              <RangeDatePicker
                initialStartDate={dayjs(banner.startTime)}
                initialEndDate={dayjs(banner.endTime)}
                onChange={handleRangeDate}
                wrapper={renderWrapper}
              />
            </div>
          </div>

          <div className={styles['date-time-wrapper']}>
            <div className={styles['time-container']}>
              <div className={`${styles.section} ${styles.start}`}>
                <div className={styles.title}>Start Time</div>
                <div className={styles.content}>
                  <DatePicker
                    initialDate={dayjs(banner.startTime)}
                    onChange={date => handleTime('start', date)}
                    showTimeOnly
                  />
                </div>
              </div>
              <div className={`${styles.section} ${styles.end}`}>
                <div className={styles.title}>End Time</div>
                <div className={styles.content}>
                  <DatePicker
                    initialDate={dayjs(banner.endTime)}
                    onChange={date => handleTime('end', date)}
                    showTimeOnly
                  />
                </div>
              </div>
            </div>
            {
              timeErrMsg && <ErrorText text={timeErrMsg} />
            }
          </div>

          <div className={styles.section}>
            <div className={styles.title}>
              Main Title
            </div>
            <div className={styles['main-title-input']}>
              <TextArea 
                value={banner.mainTitle}
                isError={mainTitleStatus === STATUS.INVALID}
                errMsg={mainTitleErrMsg}
                onInputChange={e => handleInput('mainTitle', e)}
                showCount={false}
                maxLength={null}
              />
            </div>
          </div>

          {
            banner.subTitle.map((d, index) => (
              <div key={index} className={styles.section}>
                <div className={styles.title}>
                  {`Sub Title ${index + 1}`}
                </div>
                {
                  index === 0 && (
                  <Input
                    value={d}
                    onInputChange={e => handleSubTitleChange(e, index)}
                    enableClearBtn={false}
                    isError={subTitleStatus === STATUS.INVALID}
                    errMsg={subTitleErrMsg}
                  />
                  )
                }
                {
                  index === 1 && (
                    <TextArea 
                      value={d}
                      isError={subTitleStatus2 === STATUS.INVALID}
                      errMsg={subTitleErrMsg2}
                      onInputChange={e => handleSubTitleChange(e, index)}
                      showCount={false}
                      maxLength={null}
                    />
                  )
                }
              </div>
            ))
          }

          <div className={styles.section}>
            <div className={styles.title}>
              CTA
            </div>
            <Input 
              value={banner.cta}
              onInputChange={e => handleInput('cta', e)}
              enableClearBtn={false}
              isError={ctaStatus === STATUS.INVALID}
              errMsg={ctaErrMsg}
            />
          </div>

          <div className={styles.section}>
            <div className={styles.title}>
              CTA Type/ Action
            </div>
            <div className={styles['cta-list']}>
              <DropdownList
                list={options}
                content={options.find(d => d.key === banner.ctaType).text || ''}
                selectAction={handleCtaTypeChange}
              />
            </div>
            <Input
              placeholder={showCtaValuePlaceHolder()}
              value={showCtaValue()}
              onInputChange={handleCtaValueChange}
              enableClearBtn={false}
              isError={ctaValueStatus === STATUS.INVALID}
              errMsg={ctaValueErrMsg}
            />
          </div>
        </div>
      </div>
    </div>
  );
}